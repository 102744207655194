@import "/home/runner/work/caritas-onlineBeratung-frontend/caritas-onlineBeratung-frontend/src/resources/styles/settings.scss";
.localeSwitch {
	display: flex;
	align-items: center;
	font-size: 14px;

	.select__wrapper {
		margin-top: 0;
		padding-left: 0;
	}

	.select__input__single-value {
		color: $secondary;
		&:hover {
			color: $hover-primary;
		}
	}

	span {
		font-weight: bold;
	}

	svg {
		margin-right: $grid-base;
	}

	&--vertical {
		flex-direction: column;
		color: #fff;

		svg,
		span {
			color: #fff;
			margin: 0;
			font-weight: $font-weight-medium;
		}
	}
}
