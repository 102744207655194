@import "/home/runner/work/caritas-onlineBeratung-frontend/caritas-onlineBeratung-frontend/src/resources/styles/settings.scss";
$spinnerSize: 60px;

.loading {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.5s opacity;

	&--visible {
		opacity: 1;
	}
}
