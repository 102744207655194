@import "/home/runner/work/caritas-onlineBeratung-frontend/caritas-onlineBeratung-frontend/src/resources/styles/settings.scss";
$loginOverlayDelayDesktop: 1s;
$loginOverlayDelayMobile: 2.5s;
$iconSize: 55px;
$gridSpacing: $grid-base-four;

.stage {
	background-color: var(--skin-color-primary, $primary);
	color: var(--text-color-contrast-switch, $text-invert);
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: none;

	@include breakpoint($fromXLarge) {
		display: flex;
	}

	&__headline {
		display: flex;
		flex: 1 1 0;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-bottom: 140px;
		word-break: normal;

		h1 {
			margin-bottom: $grid-base;
		}

		h4 {
			margin: 0;
			text-align: center;
			font-size: $font-size-primary;
			font-weight: $font-weight-regular;
		}
	}

	&__logos {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 0;
		align-items: flex-end;
		align-content: flex-end;
		width: 335px;
		margin-bottom: $grid-base-five;
		transform: scale(0.9);

		@media (min-width: calc(#{$xsmall} + #{$grid-base-three})) {
			transform: scale(1);
			margin-bottom: $grid-base-ten;
		}

		svg {
			width: $iconSize;
			height: $iconSize;
			margin-right: $gridSpacing;
			margin-top: $gridSpacing;

			&.raphaelswerk {
				padding-top: 8px;
				margin-left: -5px;
				margin-right: calc(#{$gridSpacing} - 5px);
				width: 64px;
				height: 64px;
			}

			&:first-child {
				margin-left: 54px;
			}

			&:nth-child(-n + 3) {
				margin-top: 0;
			}

			&:nth-child(4) {
				margin-left: $grid-base-two;
				margin-right: $grid-base-three;
			}

			&:nth-child(7) {
				margin-right: 0;
			}
		}
	}

	&--animated {
		display: flex;
		width: 100vw;

		&.stage--animation-done {
			display: none;

			@include breakpoint($fromXLarge) {
				display: flex;
			}
		}

		&.stage--ready {
			animation: overlayAnimation 1.4s ease forwards;
			animation-delay: $loginOverlayDelayMobile;
		}

		.stage__headline {
			margin-bottom: 80px;
		}

		@keyframes overlayAnimation {
			from {
				left: 0;
			}
			to {
				left: -100vw;
			}
		}

		@include breakpoint($fromXLarge) {
			&.stage--ready {
				animation: overlayAnimation 1s ease forwards;
				animation-delay: $loginOverlayDelayDesktop;
			}

			@keyframes overlayAnimation {
				from {
					width: 100vw;
				}
				to {
					width: 40vw;
				}
			}
		}
	}

	&__spinner {
		animation-delay: 2s;
		animation: hideSpinner 4s ease forwards;

		@include breakpoint($fromXLarge) {
			animation-duration: 2s;
		}

		@keyframes hideSpinner {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
	}
}
