@import "/home/runner/work/caritas-onlineBeratung-frontend/caritas-onlineBeratung-frontend/src/resources/styles/settings.scss";
.notifications {
	position: fixed;
	display: flex;
	flex-direction: column;
	width: calc(100% - #{$grid-base-four});
	z-index: 500;
	left: $grid-base-two;
	right: $grid-base-two;
	top: $grid-base-two;

	@include breakpoint($fromMedium) {
		width: 300px;
		left: auto;
		right: $grid-base-three;
		top: $grid-base-three;
	}

	& > :not(:first-child) {
		margin-top: 3px;
	}
}
